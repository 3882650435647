import {radioButtonsFunc} from "../creationPageMixins/radioButtonsFunc";
import {queryFilterMixin} from "./queryFilterMixin";
import {isArray} from "chart.js/helpers";
import {exportRequestsSocketMixin} from "@/mixins/socketMixins/socketMixin";

export const routeFilter = {

  mixins: [radioButtonsFunc, queryFilterMixin, exportRequestsSocketMixin],

  methods: {
    changeRouteParams(params, newParams){

      Object.keys(newParams).map((itemNewParam) => {
        Object.keys(params).map((itemParam) => {
          if(itemNewParam === itemParam) params[itemParam] = newParams[itemNewParam]
        })
      })

      Object.keys(params).map((itemParams) => {
        if(params[itemParams] === undefined || params[itemParams] === '') delete params[itemParams];
      })

      if(Object.keys(params).length === 0) {
        this.$router.push({ path: '', query: {}})
      } else {
        // If old params not equals new params
        if(
          JSON.stringify(this.$route.query).replace(/"/g, '') !==
          JSON.stringify(params).replace(/"/g, '')
        ) {
          this.$router.push({ path: '', query: params })
        }

      }

    },

    generateMainFilterFromUrl(next, that, paginate = false) {
      let forPage, page

      // generate filter if has get params in URL
      if(!next && !paginate) {
        that.page = that.filterGetParams.page === undefined ? that.onePage : parseInt(that.filterGetParams.page)
        page = that.onePage
        forPage = that.filterGetParams.count === undefined ?
            that.forPage * that.page :
            parseInt(that.filterGetParams.count) * that.page
      } else {
        page = that.filterGetParams.page === undefined ? that.onePage : parseInt(that.filterGetParams.page)
        forPage = that.forPage
      }

      return {forPage, page}
    },

    generateParamsFilterFromUrl(that) {
      let filterParams = Object.assign({}, that.filterGetParams)

      delete filterParams.page
      delete filterParams.count

      Object.keys(filterParams).map((item) => {
        if(filterParams[item] === undefined) {
          that['filter' + item[0].toUpperCase() + item.slice(1)] = ''
          delete filterParams[item]
        } else {
          that['filter' + item[0].toUpperCase() + item.slice(1)] = filterParams[item]
        }
      })
    },

    generateDateForFilter(date, type) {
      return [this.$moment(date.split(',')[0]).format(type),
        this.$moment(date.split(',')[1]).format(type)]
    },

    generateNextPAgeUrl() {
      let getParams = Object.assign({}, this.$route.query)

      if(this.$route.query.page === undefined) {
        getParams.page = parseInt(this.page) + parseInt(this.onePage)
        getParams.count = this.forPage
      } else {
        getParams.page = parseInt(this.$route.query.page) + parseInt(this.onePage)
        getParams.count = this.forPage
      }

      let positionScroll = window.pageYOffset
      this.$router.push({path: '', query: getParams}).then(() => {
        window.scrollTo(0, positionScroll);
      })

    },

    generatePaginationPageUrl(page) {
      let getParams = Object.assign({}, this.$route.query)

      getParams.page = parseInt(page)
      getParams.count = this.forPage
      getParams.pagination = 'true'

      this.$router.push({path: '', query: getParams})
    },

    checkCountFilterFromUrl(that, withoutParams = false) {
      let filterParams = Object.assign({}, that.filterGetParams)

      delete filterParams.page
      delete filterParams.count

      if(withoutParams) {
        withoutParams.map((item) => {
          delete filterParams[item]
        })
      }


      let counter = 0

      Object.keys(filterParams).map((item) => {
        if(filterParams[item] !== undefined) {
          counter = counter + 1
        }
      })

      return counter
    },

    getCheckedRows(name) {
      let elements, array = []
      elements = document.querySelectorAll('[data-'+ name +']:checked')

      elements.forEach((item) => {
        array.push(item.getAttribute('data-'+ name))
      })

      return array
    },

    showMore() {
      this.generateNextPAgeUrl(this)

      this.filter(true)
    },

    paginate(page) {
      this.generatePaginationPageUrl(page)

      this.filter(false)
    },

    checkCountFilter(withoutParams = false) {
      this.countFilterParams = this.checkCountFilterFromUrl(this, withoutParams)
    },

    resetFilter() {
      this.$router.push({path: '', query: {}})
      this.filter()
    },

    changeFilter(data) {
      data.page = this.onePage
      data.count = this.forPage

      this.getFilterParam()
      this.changeRouteParams(this.mergeRouteParams(), data)

      this.filter()
    },

    changeSort(data) {
      let dataParams = {}
      dataParams.page = this.onePage
      dataParams.count = this.forPage

      //add sort param
      dataParams[data.name] = data.type

      // reset old sort params
      this.resetValues(this.sortParams, '')

      this.getFilterParam()
      this.changeRouteParams(this.mergeRouteParams(), dataParams)

      this.filter()
    },

    generateSortQueryParams(myQuery, params) {
      Object.keys(params).map(param => {
        if (this.sortParams[param] && this.sortParams[param] !== '')
          myQuery.sort(`${this.sortParams[param].replace('+', '')}${params[param]}`)
      })
    },

    mergeRouteParams() {
      if(this.filterGetParams && this.sortParams) {
        return {...this.filterGetParams,...this.sortParams}
      }
      if(this.filterGetParams) {
        return this.filterGetParams
      }
      if(this.sortParams) {
        return this.sortParams
      }
    },

    initialTabFilter({tabsVariableName, tabsActiveName, indexActiveTab, tabFilterName, tabItemIdName}) {
      this.skipRadioChecked(this[tabsVariableName], tabsActiveName)
      this[tabsVariableName][indexActiveTab][tabsActiveName] = true

      this.getFilterParam()

      let data = {
        page: this.filterGetParams.page ? this.filterGetParams.page : this.onePage,
        count: this.filterGetParams.count ? this.filterGetParams.count : this.forPage
      }

      if(indexActiveTab === 0) data[tabFilterName] = ''
      else data[tabFilterName] = this[tabsVariableName][indexActiveTab][tabItemIdName]

      this.changeRouteParams(this.filterGetParams, data)

      this.filter()
    },

    generateFilterQueryParams(myQuery, params) {
      Object.keys(params).map(item => {
        if (this[item] !== '') myQuery.where(params[item], this[item])
      })
    },

    mainChangeTab(item, tabs) {
      this.skipRadioChecked(tabs, 'active')
      tabs[item.name].active = true
    },

    reload() {
      this.$store.commit('setGlobalUpdateTable', true)
      this.filter()
    },

    changeTab(tab, tabs = false) {
      let currentTabs = this.navTabs
      if(tabs){
        currentTabs = this[tabs]
      }
      this.skipRadioChecked(currentTabs, 'active')
      currentTabs[tab].active = true
      this.changeFilter({type: tab})
    },

    changeTabSub(tab, tabs = false, parentTab) {
      let currentTabs = this.navTabs[parentTab]['subTabs']
      if(tabs){
        currentTabs = this[tabs][parentTab]['subTabs']
      }
      this.skipRadioChecked(currentTabs, 'active')
      currentTabs[tab].active = true
      this.changeFilter({subType: tab})
    },


    createDownloadLink({data, name, type}){
      const blob = new Blob([data], {type: `application/${type}`})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    },


    setActivePermissionVisibleTab(tabsName){
      let findPermissionVisibleTab = this._.findKey(this[tabsName], {permissionVisible: true})
      // this.skipRadioChecked(this[tabsName], 'active')
      // findPermissionVisibleTab.active = true
      //
      // if(setFilterType && this.filterType === ''){
      //   this.filterType = findPermissionVisibleTab.value
      // }

      if(findPermissionVisibleTab){
        console.log(findPermissionVisibleTab);
        this.changeTab(findPermissionVisibleTab)
      }
    },

    activeTabByPermissions(tabsName, arrayPermissions){
      for(let permission in arrayPermissions){

        // if pass array permissions in one
        let permissionArrayFlag = true
        if(isArray(arrayPermissions[permission])){
          arrayPermissions[permission].map(permissionArrayItem => {
            if(!this._.has(this.currentPermissions, permissionArrayItem)) {
              permissionArrayFlag = false
            }
          })

          if(permissionArrayFlag){
            this[tabsName][permission].permissionVisible = true
          } else {
            this[tabsName][permission].permissionVisible = false
          }
          continue
        }

        if(this._.has(this.currentPermissions, arrayPermissions[permission])){
          this[tabsName][permission].permissionVisible = true
        } else {
          this[tabsName][permission].permissionVisible = false
        }
      }
    },

    hideTabAllIfVisibleOnlyOne(tabsName, tabAllName, checkingArray){

      let activeTabsCount = 0
      for(let tabName in this[tabsName]){
        let checkFlag = true
        checkingArray.map(check => {
          if(!this[tabsName][tabName][check]){
            checkFlag = false
          }
        })

        if(checkFlag){
          activeTabsCount ++
        }
      }

      if(activeTabsCount === 2){
        this[tabsName][tabAllName].permissionVisible = false
      }
    },

    exportTableFiles(url, type, dispatch, fileName) {

      this.$store.dispatch(dispatch, {filter: url , type: type}).then((response) => {
        if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          if(type !== 'csv' && type !== 'xlsx') {
            this.openNotify('success', 'common_notificationExportRequest')
            return
          }

          this.createDownloadLink({data: response.data, name: `${fileName}.${type}`, type: type})
        }
        else {
          setTimeout(() => {
            this.openNotify('error', 'common_notificationUndefinedError')
          }, 200)
        }
      }).catch(error => this.createErrorLog(error))
    },

  },



}
